import {config} from '@verily-src/phaf-runtime-helpers';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import EmailUnsubscribe from '../../pages/EmailUnsubscribe/EmailUnsubscribe';
import NotificationPreferences from '../../pages/NotificationPreferences/NotificationPreferences';
import Profile from '../../pages/Profile/Profile';
import ResearchStudy from '../../pages/ResearchStudy/ResearchStudy';
import UpdatePassword from '../../pages/UpdatePassword/UpdatePassword';

export default function Root() {
  const isDarkModeEnabled = config.getBoolean('FEATURE_DARK_MODE_ENABLED');

  return (
    <VerilyMeThemeProvider isDarkModeEnabled={isDarkModeEnabled}>
      <BrowserRouter>
        <Routes>
          <Route path="/me/profile" element={<Profile />}></Route>
          <Route
            path="/me/profile/update-password"
            element={<UpdatePassword />}
          ></Route>
          <Route
            path="/me/profile/research-study"
            element={<ResearchStudy />}
          ></Route>
          <Route
            path="/me/profile/notification-preferences"
            element={<NotificationPreferences />}
          ></Route>
          <Route
            path="/email/unsubscribe"
            element={<EmailUnsubscribe />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </VerilyMeThemeProvider>
  );
}
