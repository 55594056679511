import {Avatar, Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {LocalPoliceIcon} from '@verily-src/react-design-system';
import {VerificationState} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/user_profile_service';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import SrText from '@verily-src/verily1-verily-me-web-common-typescript/src/components/SrText/SrText';
import {useTranslation} from 'react-i18next';

interface UserNameProps {
  fullName: string;
  initials: string;
  isLoading: boolean;
  verificationState?: VerificationState;
}

type DefinedVerificationState = Exclude<
  VerificationState,
  VerificationState.VERIFICATION_STATE_UNKNOWN
>;

export default function UserName(props: UserNameProps) {
  const {fullName, initials, isLoading, verificationState} = props;
  const theme = useTheme();
  const {t} = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const idvEnabled = config.getBoolean('FEATURE_IDV_STATUS_ENABLED');

  const defaultBoxProps = {
    display: 'flex',
    height: '100%',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  };

  const mobileBoxProps = {
    ...defaultBoxProps,
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
    },
  };

  const boxProps = isMobile ? mobileBoxProps : defaultBoxProps;

  const hasVerification = idvEnabled && verificationState;

  const verificationStateToString = (
    state: DefinedVerificationState
  ): string => {
    switch (state) {
      case VerificationState.FAILURE:
        return t('verification-rejected');
      case VerificationState.SUCCESS:
        return t('verification-success');
      case VerificationState.CANCELLED:
      case VerificationState.ABANDONED:
      case VerificationState.IN_PROGRESS:
        return t('verification-in-progress');
    }
  };

  const verificationStateColor = (state: DefinedVerificationState): string => {
    switch (state) {
      case VerificationState.FAILURE:
        return theme.palette.error.main;
      case VerificationState.SUCCESS:
        return theme.palette.success.main;
      case VerificationState.IN_PROGRESS:
      case VerificationState.CANCELLED:
      case VerificationState.ABANDONED:
        return theme.palette.warning.main;
    }
  };

  return (
    <Box sx={boxProps} data-testid="user-name-container">
      <Avatar
        sx={{
          width: theme.spacing(8),
          height: theme.spacing(8),
          fontSize: 28,
          fontWeight: 600,
          margin: 0,
          color: theme.palette.text.muted,
          backgroundColor: theme.palette.background.secondaryCanvas,
          border: !isLoading && `1px solid ${theme.palette.neutral?.divider}`,
        }}
      >
        <SrText text={`${t('profile-photo')}, ${fullName}`} />
        {initials}
      </Avatar>
      <Box
        sx={{
          display: 'flex',
          alignItems: isMobile || !hasVerification ? 'center' : undefined,
          height: '100%',
          flexDirection: hasVerification ? 'column' : undefined,
        }}
      >
        {isLoading ? (
          <PillSkeleton width={theme.spacing(17.5)} height={theme.spacing(4)} />
        ) : hasVerification ? (
          <div data-testid="idv-container">
            <Typography variant="display6">{fullName}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: theme.spacing(0.5),
              }}
            >
              <LocalPoliceIcon
                sx={{color: verificationStateColor(verificationState)}}
              />
              <Typography
                color={verificationStateColor(verificationState)}
                variant="captionem"
              >
                {verificationStateToString(verificationState)}
              </Typography>
            </Box>
          </div>
        ) : (
          <Typography variant="display6">{fullName}</Typography>
        )}
      </Box>
    </Box>
  );
}
