import {ThemeProvider} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import EmailUnsubscribe from '../../pages/EmailUnsubscribe/EmailUnsubscribe';
import NotificationPreferences from '../../pages/NotificationPreferences/NotificationPreferences';
import Profile from '../../pages/Profile/Profile';
import ResearchStudy from '../../pages/ResearchStudy/ResearchStudy';
import UpdatePassword from '../../pages/UpdatePassword/UpdatePassword';

export default function Root() {
  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <BrowserRouter>
        <Routes>
          <Route path="/me/profile" element={<Profile />}></Route>
          <Route
            path="/me/profile/update-password"
            element={<UpdatePassword />}
          ></Route>
          <Route
            path="/me/profile/research-study"
            element={<ResearchStudy />}
          ></Route>
          <Route
            path="/me/profile/notification-preferences"
            element={<NotificationPreferences />}
          ></Route>
          <Route
            path="/email/unsubscribe"
            element={<EmailUnsubscribe />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
