import {Container, useTheme} from '@mui/material';

interface LayoutProps {
  header?: JSX.Element;
  children: JSX.Element | JSX.Element[];
}

export default function Layout(props: LayoutProps) {
  const theme = useTheme();

  const pageStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    minWidth: 288,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.canvas,
  };

  // https://www.figma.com/file/BgJmfj8DNWi7VgqzqxGEUr/Verily-Me-UX-Design-Spec?type=design&node-id=16762-97086&mode=dev
  return (
    <div style={pageStyle}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
          [theme.breakpoints.down('sm')]: {
            minWidth: 320,
            maxWidth: '100%',
            padding: theme.spacing(0, 2),
            alignContent: 'center',
          },
          [theme.breakpoints.up('sm')]: {
            minWidth: false,
            maxWidth: 636,
            padding: theme.spacing(0, 4),
            alignContent: 'center',
          },
          [theme.breakpoints.up('md')]: {
            minWidth: false,
            maxWidth: 1136,
            padding: theme.spacing(0, 4),
            alignContent: 'flex-start',
          },
        }}
        maxWidth={false} // Disable max-width to allow UX's custom specifications
      >
        {props.header}
        {props.children}
      </Container>
    </div>
  );
}
