import {Box, Container, Stack, Typography, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  Button,
  Card,
  CircularProgress,
  Snackbar,
  Switch,
} from '@verily-src/react-design-system';
import {
  NotificationUserPreference_NotificationCategory,
  NotificationUserPreference_NotificationChannel,
} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/notification_user_preferences_service';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {navigateToUrl} from 'single-spa';
import NotificationUserPreferencesService from '../../services/NotificationUserPreferencesService';
import logError from '../../utils/logError';

const EmailUnsubscribe = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);
  const [tasks, setTasks] = useState<boolean>(true); // tasks defaults to true if not set
  const [messages, setMessages] = useState<boolean>(true); // messages defaults to true if not set
  const [infoToast, setInfoToast] = useState<boolean>(false);

  const isMobile = useIsMobile();
  const {t} = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const categoryNotificationsEnabled = config.getBoolean(
    'FEATURE_CATEGORY_NOTIFICATIONS_ENABLED'
  );
  const name = searchParams.get('cid');
  const unsubscribeHash = searchParams.get('hash');

  const updateCategoryPreference = async (
    enabled: boolean,
    type: NotificationUserPreference_NotificationCategory
  ) => {
    // Fire the update, reset the state if it fails
    try {
      updateCategory(enabled, type);
      setInfoToast(true);
      NotificationUserPreferencesService.batchUpdateNotificationUserPreferencesUnauthenticated(
        name,
        unsubscribeHash,
        {
          channel: NotificationUserPreference_NotificationChannel.EMAIL,
          category: type,
          value: {
            value: {
              oneofKind: 'enabled',
              enabled,
            },
          },
          statusSet: true,
        }
      );
    } catch (err) {
      logError(err);
      updateCategory(!enabled, type);
      setInfoToast(false);
      setErrorOccurred(true);
    }
  };

  const updateCategory = (
    enabled: boolean,
    type: NotificationUserPreference_NotificationCategory
  ) => {
    switch (type) {
      case NotificationUserPreference_NotificationCategory.TASK:
        setTasks(enabled);
        break;
      case NotificationUserPreference_NotificationCategory.MESSAGE:
        setMessages(enabled);
        break;
    }
  };

  const handleUnsubscribe = async () => {
    setLoading(true);
    try {
      if (categoryNotificationsEnabled) {
        await NotificationUserPreferencesService.unsubscribeFromEmail(
          name,
          unsubscribeHash
        );
        setTasks(false);
        setMessages(false);
        setInfoToast(true);
      } else {
        await NotificationUserPreferencesService.unsubscribeFromEmail(
          name,
          unsubscribeHash
        );
        setUnsubscribed(true);
      }
    } catch (err) {
      setErrorOccurred(true);
      logError(err);
    } finally {
      setLoading(false);
    }
  };

  const listCategoryNotificationPreferences = async () => {
    setLoading(true);
    try {
      const response =
        await NotificationUserPreferencesService.listNotificationUserPreferencesUnauthenticated(
          name,
          unsubscribeHash
        );
      response.preferences.forEach(preference => {
        if (
          preference.channel ===
            NotificationUserPreference_NotificationChannel.EMAIL &&
          preference.statusSet &&
          preference.value?.value?.oneofKind === 'enabled'
        ) {
          const value = preference.value.value as {enabled: boolean};
          switch (preference.category) {
            case NotificationUserPreference_NotificationCategory.TASK:
              setTasks(value.enabled);
              break;
            case NotificationUserPreference_NotificationCategory.MESSAGE:
              setMessages(value.enabled);
              break;
          }
        }
      });
    } catch (err) {
      setErrorOccurred(true);
      logError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categoryNotificationsEnabled) {
      listCategoryNotificationPreferences();
    }
  }, []);

  useEffect(() => {
    document.title = `Verily Me | ${
      unsubscribed ? t('unsubscribed-header') : t('unsubscribe-header')
    }`;
  }, [unsubscribed]);

  if (errorOccurred) {
    return (
      <ErrorView
        title={t('something-went-wrong')}
        subtitle={t('were-having-an-issue')}
        refreshText={t('refresh')}
      />
    );
  }

  if (loading) {
    return (
      <CircularProgress
        useUnlaunchedComponent
        data-testid="loading-spinner"
        aria-label={t('spinner-label')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  const sections: Array<{
    switch: JSX.Element;
    title: string;
    cardTitle: string;
    exampleText: string;
  }> = [
    {
      title: t('tasks'),
      cardTitle: t('email-box'),
      switch: (
        <Switch
          useUnlaunchedComponent
          data-testid="tasks-switch"
          inputProps={{'aria-label': t('tasks-switch')}}
          checked={tasks}
          onChange={() => {
            updateCategoryPreference(
              !tasks,
              NotificationUserPreference_NotificationCategory.TASK
            );
          }}
          pendo-task-email-settings={
            tasks ? 'switchEmailSettingOn' : 'switchEmailSettingOff'
          }
          sx={{
            position: 'absolute',
            zIndex: 2,
            right: 0,
            // TODO - Remove `before psuedo class` once RDS supports android a11y styling
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 48,
              height: 48,
            },
          }}
        />
      ),
      exampleText: t('tasks-example'),
    },
    {
      title: t('messages'),
      cardTitle: t('email-box'),
      switch: (
        <Switch
          useUnlaunchedComponent
          data-testid="messages-switch"
          inputProps={{'aria-label': t('messages-switch')}}
          checked={messages}
          onChange={() => {
            updateCategoryPreference(
              !messages,
              NotificationUserPreference_NotificationCategory.MESSAGE
            );
          }}
          pendo-message-email-settings={
            messages ? 'switchEmailSettingOn' : 'switchEmailSettingOff'
          }
          sx={{
            position: 'absolute',
            zIndex: 2,
            right: 0,
            // TODO - Remove `before psuedo class` once RDS supports android a11y styling
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 48,
              height: 48,
            },
          }}
        />
      ),
      exampleText: t('messages-example'),
    },
  ];

  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: unsubscribed && '292px',
        overflowX: 'hidden',
      }}
      data-testid="unsubscribe-box"
    >
      {!unsubscribed && (
        <Box
          component={'img'}
          sx={{
            width: isMobile ? '375px' : '636px',
            height: isMobile ? '292px' : '355px',
          }}
          src={require('../../assets/unsubscribe.svg')}
          alt={t('unsubscribe-alt')}
        />
      )}
      <Typography
        variant="display5"
        component="h1"
        sx={{
          mb: 4,
          maxWidth: unsubscribed ? theme.spacing(50) : undefined,
          fontSize: isMobile && '21px',
        }}
      >
        {categoryNotificationsEnabled
          ? t('unsubscribe-category-header')
          : unsubscribed
          ? t('unsubscribed-header')
          : t('unsubscribe-header')}
      </Typography>
      {!categoryNotificationsEnabled && (
        <Typography
          variant="body1"
          color={theme.palette.text.muted}
          sx={{mb: unsubscribed ? 0 : 3}}
        >
          {unsubscribed ? t('unsubscribed-body1') : t('unsubscribe-body1')}
        </Typography>
      )}
      {!categoryNotificationsEnabled && (
        <Typography
          variant="body1"
          color={theme.palette.text.muted}
          sx={{mb: 3}}
        >
          {unsubscribed ? t('unsubscribed-body2') : t('unsubscribe-body2')}
        </Typography>
      )}
      {categoryNotificationsEnabled && (
        <Box
          mb={3}
          maxWidth={isMobile ? '490px' : undefined}
          width={isMobile ? '95%' : '572px'}
          data-testid="switches-container"
        >
          <Stack spacing={2} textAlign={'start'}>
            <Typography variant="body1em" sx={{pb: 1}} textAlign={'start'}>
              {`${t('notification-preferences')}:`}
            </Typography>
            {sections.map((section, idx) => (
              <Stack spacing={2} key={idx}>
                <Typography variant="display6" fontSize={'1.125rem'}>
                  {section.title}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={'0.875rem'}
                  color={theme.palette.text.muted}
                >
                  {section.exampleText}
                </Typography>
                <Card
                  title={section.cardTitle}
                  useUnlaunchedComponent
                  disableFocus
                  disableButton
                  action={{
                    onClick: () => {},
                    IconComponent: section.switch,
                    hasIcon: true,
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
      <div style={{marginBottom: theme.spacing(3)}}>
        <Button
          onClick={() => navigateToUrl('/me/profile/notification-preferences')}
          variant="filled"
          size="large"
        >
          {categoryNotificationsEnabled
            ? t('unsubscribe-category-manage')
            : t('email-preferences')}
        </Button>
      </div>
      {!unsubscribed && (
        <Button
          color="primary"
          onClick={handleUnsubscribe}
          pendo-id="unsubscribe-all-button"
          sx={{
            // TODO - Remove `before psuedo class` once RDS supports android a11y styling
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: 48,
            },
          }}
        >
          {categoryNotificationsEnabled
            ? t('unsubscribe-category-all')
            : t('unsubscribe')}
        </Button>
      )}
      {categoryNotificationsEnabled && (
        <Container sx={{mt: 5}}>
          <Typography
            variant="body1"
            color={theme.palette.text.muted}
            fontSize={'0.875rem'}
            gap={'16px'}
          >
            {t('notifications-disclaimer')}
          </Typography>
        </Container>
      )}
      <Snackbar
        ContentProps={{
          sx: {
            maxWidth: 600,
          },
        }}
        data-testid="info-toast"
        color={'general'}
        open={infoToast}
        onClose={() => setInfoToast(false)}
        text={t('saved-changes')}
      />
    </Box>
  );
};

export default EmailUnsubscribe;
